import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {StudentEntity} from "../model/StudentEntity";
import {BasicDto} from "../dto/BasicDto";
import {CoursePriceDto} from "../dto/CoursePriceDto";

@Injectable()
export class LoginService extends ParentService{

  public login(email: string, password: string) {
    return this.http.post(this.shared.MAGNAMENT_URL+'/login?username='+email+'&password='+password, this.shared.headers());
  }

}
