import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {CourseEntity} from "../model/CourseEntity";
import {CourseTypeEntity} from "../model/CourseTypeEntity";
import {ModalityEntity} from "../model/ModalityEntity";
import {TurnEntity} from "../model/TurnEntity";
import {SiteEntity} from "../model/SiteEntity";
import {WorkgroupEntity} from "../model/WorkgroupEntity";
import {TutorEntity} from "../model/TutorEntity";
import {HospitalEntity} from "../model/HospitalEntity";
import {SpecialityEntity} from "../model/SpecialityEntity";
import {CourseFrontPageEntity} from "../model/CourseFrontPageEntity";
import {CourseStatisticsDto} from "../dto/CourseStatisticsDto";
import {UniversityEntity} from "../model/UniversityEntity";


@Injectable()
export class PlaceService extends ParentService{

  public getHospitals(corpId) {
    return this.http.get<HospitalEntity[]>(this.shared.MAGNAMENT_URL+'/getHospitals/'+corpId, this.shared.headers());
  }

  public saveHospital(hospital) {
    return this.http.post<HospitalEntity>(this.shared.MAGNAMENT_URL+'/saveHospital', hospital);
  }


  public getUniversities(corpId) {
    return this.http.get<UniversityEntity[]>(this.shared.MAGNAMENT_URL+'/getUniversities/'+corpId, this.shared.headers());
  }

  public saveUniversity(university) {
    return this.http.post<UniversityEntity>(this.shared.MAGNAMENT_URL+'/saveUniversity', university);
  }
}
