import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {CourseEntity} from "../model/CourseEntity";
import {CourseTypeEntity} from "../model/CourseTypeEntity";
import {ModalityEntity} from "../model/ModalityEntity";
import {TurnEntity} from "../model/TurnEntity";
import {SiteEntity} from "../model/SiteEntity";
import {WorkgroupEntity} from "../model/WorkgroupEntity";
import {TopicEntity} from "../model/TopicEntity";
import {SubjectEntity} from "../model/SubjectEntity";
import {BankPackageEntity} from "../model/BankPackageEntity";
import {BankItemEntity} from "../model/BankItemEntity";


@Injectable()
export class BankService extends ParentService{

  public getBankPackages() {
    return this.http.get<BankPackageEntity[]>(this.shared.MAGNAMENT_URL+'/getBankPackage/'+this.shared.CORP_ID, this.shared.headers());
  }

  public saveBankPackage(bankPackage) {
    return this.http.post<BankPackageEntity>(this.shared.MAGNAMENT_URL+'/saveBankPackage', bankPackage);
  }




  public getBankItems(bankPackageId) {
    return this.http.get<BankItemEntity[]>(this.shared.MAGNAMENT_URL+'/getBankItems/'+bankPackageId, this.shared.headers());
  }

  public saveItem(bankItem) {
    return this.http.post<BankItemEntity>(this.shared.MAGNAMENT_URL+'/saveBankItem', bankItem);
  }

}
