import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {LibraryFolderEntity} from "../model/LibraryFolderEntity";
import {LibraryEntity} from "../model/LibraryEntity";
import {HttpHeaders} from "@angular/common/http";
import {JustReferenceDto} from "../dto/JustReferenceDto";
import {EmailTemplateEntity} from "../model/EmailTemplateEntity";

@Injectable()
export class EmailTemplateService extends ParentService{

  public getEmailTemplates(){
    return this.http.get<EmailTemplateEntity[]>(this.shared.MAGNAMENT_URL+'/getEmailTemplates/'+this.shared.CORP_ID);
  }

  public saveEmailTemplate(emailTemplate){
    return this.http.post<EmailTemplateEntity>(this.shared.MAGNAMENT_URL+'/saveEmailTemplate',emailTemplate);
  }

}
