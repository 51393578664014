import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {CalendarDto} from "../dto/CalendarDto";
import {ExamSettingEntity} from "../model/ExamSettingEntity";
import {SubjectEntity} from "../model/SubjectEntity";
import {ExamDto} from "../dto/ExamDto";
import {QuestionEntity} from "../model/QuestionEntity";
import {JustReferenceDto} from "../dto/JustReferenceDto";
import {ResultEntity} from "../model/ResultEntity";
import {ResultSubjectEntity} from "../model/ResultSubjectEntity";
import {ResultTopicEntity} from "../model/ResultTopicEntity";
import {ExamEntity} from "../model/ExamEntity";
import {Shared} from "../view/shared/shared";
import {ExamQuestionSettingEntity} from "../model/ExamQuestionSettingEntity";
import {ExamQuestionSettingDto} from "../model/ExamQuestionSettingDto";
import {ExamTemplateEntity} from "../model/ExamTemplateEntity";
import {ExamSettingGroupEntity} from "../model/ExamSettingGroupEntity";

@Injectable()
export class ExamService extends ParentService{

  public saveExamSetting(exam: ExamSettingEntity){
    return this.http.post<ExamSettingEntity>(this.shared.MAGNAMENT_URL+'/saveExamSetting', exam);
  }

  public getExamSetting(){
    return this.http.get<ExamSettingEntity[]>(this.shared.MAGNAMENT_URL+'/getExamSetting/'+this.shared.CORP_ID);
  }

  public getExamTemplate(){
    return this.http.get<ExamTemplateEntity[]>(this.shared.MAGNAMENT_URL+'/getExamTemplate/'+this.shared.CORP_ID);
  }

  public getExamQuestions(examSettingId){
    return this.http.get<ExamQuestionSettingDto>(this.shared.MAGNAMENT_URL+'/getExamQuestion/'+ examSettingId);
  }

  public getExamsSettings(obj){
    return this.http.post<ExamSettingEntity[]>(this.shared.MAGNAMENT_URL+'/getExamsSettings', obj);
  }

  public addQuestionToExam(questionId, examId){
    return this.http.get<ExamQuestionSettingEntity>(this.shared.MAGNAMENT_URL+'/addQuestionToExam/'+this.shared.CORP_ID+'/'+questionId+'/'+examId);
  }

  public removeQuestionToExam(questionId, examId){
    return this.http.get<number>(this.shared.MAGNAMENT_URL+'/removeQuestionToExam/'+this.shared.CORP_ID+'/'+questionId+'/'+examId);
  }

  public recalculateExam(examSettingId){
    return this.http.get<any>(this.shared.MAGNAMENT_URL+'/recalculateExam/'+examSettingId);
  }

  public saveQuestionList(toSave){
    return this.http.post<any>(this.shared.MAGNAMENT_URL+'/saveQuestionList/', toSave);
  }

  public getQuestionGroups(){
    return this.http.get<ExamSettingGroupEntity[]>(this.shared.MAGNAMENT_URL+'/getQuestionGroups/'+this.shared.CORP_ID);
  }
}
