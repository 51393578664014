import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {Shared} from "./shared/shared";
import {SwiperModule} from "swiper/angular";
import {Device} from "@ionic-native/device/ngx";
import {MyHttpInterceptor} from "../service/HttpInterceptor";
import {Toolbar} from "./toolbar/toolbar";
import {Menu} from "./menu/menu";
import {MagnamentModule} from "./magnament/magnament.module";
import {LoginModule} from "./login/login.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";


@NgModule({
  declarations: [AppComponent, Toolbar, Menu],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, SwiperModule, MagnamentModule, LoginModule, NgSelectModule, FormsModule],
  providers: [{provide: RouteReuseStrategy, useClass: IonicRouteStrategy}, Shared, Device, MyHttpInterceptor],
  bootstrap: [AppComponent]
})
export class AppModule {}
