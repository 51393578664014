import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {QuestionEntity} from "../model/QuestionEntity";
import {CommonQuestionDto} from "../dto/CommonQuestionDto";
import {AnswerEntity} from "../model/AnswerEntity";
import {QuestionMultimediaEntity} from "../model/QuestionMultimediaEntity";
import {QuestionAdminDto} from "../dto/QuestionAdminDto";
import {JustReferenceDto} from "../dto/JustReferenceDto";

@Injectable()
export class QuestionService extends ParentService{

  public async getQuestions(){
    const corps = await this.shared.getCorpGroups();
    return this.http.get<QuestionEntity[]>(this.shared.MAGNAMENT_URL+'/getQuestions/'+ corps);
  }

  public getQuestion(reference){
    return this.http.get<QuestionEntity>(this.shared.MAGNAMENT_URL+'/getQuestion/'+reference);
  }

  public searchQuestions(searchQuestions){
    return this.http.post<QuestionEntity[]>(this.shared.MAGNAMENT_URL+'/searchQuestions', searchQuestions);
  }

  public importQuestions(obj){
    return this.http.post<JustReferenceDto>(this.shared.MAGNAMENT_URL+'/importQuestions', obj);
  }

  public async getCommonQuestion(){
    const corps = await this.shared.getCorpGroups();
    return this.http.get<CommonQuestionDto>(this.shared.MAGNAMENT_URL+'/getCommonQuestion/'+corps);
  }

  public getAnswerQuestion(questionReference){
    return this.http.get<AnswerEntity[]>(this.shared.MAGNAMENT_URL+'/getAnswerQuestion/'+questionReference);
  }

  public getMultimediaQuestion(questionReference){
    return this.http.get<QuestionMultimediaEntity[]>(this.shared.MAGNAMENT_URL+'/getMultimediaQuestion/'+questionReference);
  }

  public getOriginTagQuestion(questionReference){
    return this.http.get<QuestionAdminDto>(this.shared.MAGNAMENT_URL+'/getOriginTagQuestion/'+questionReference);
  }

  public saveMultimedia(mul: QuestionMultimediaEntity){
    return this.http.post<QuestionMultimediaEntity>(this.shared.MAGNAMENT_URL+'/saveMultimedia', mul);
  }

  public saveQuestion(que){
    return this.http.post<QuestionEntity>(this.shared.MAGNAMENT_URL+'/saveQuestion', que);
  }

  public uploadMultimedia(file){
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post<JustReferenceDto>(this.shared.MAGNAMENT_URL+'/uploadMultimedia', formData);
  }
}
