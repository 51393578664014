import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Magnament } from './magnament';

const routes: Routes = [
  {
    path: '',
    component: Magnament,
    children: [
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
      },{
        path: 'exams',
        loadChildren: () => import('./exams/exams.module').then(m => m.ExamsModule)
      },{
        path: 'questions',
        loadChildren: () => import('./questions/questions.module').then(m => m.QuestionsModule)
      },{
        path: 'courses',
        loadChildren: () => import('./coursesData/coursesData.module').then(m => m.CoursesDataModule)
      },{
        path: 'subjects',
        loadChildren: () => import('./subjectsData/subjectsData.module').then(m => m.SubjectsDataModule)
      },{
        path: 'workgroups',
        loadChildren: () => import('./workgroup/workgroup.module').then(m => m.WorkgroupModule)
      },{
        path: 'tutors',
        loadChildren: () => import('./tutor/tutor.module').then(m => m.TutorModule)
      },{
        path: 'hospitals',
        loadChildren: () => import('./hospitals/hospitals.module').then(m => m.HospitalsModule)
      },{
        path: 'universities',
        loadChildren: () => import('./universities/universities.module').then(m => m.UniversitiesModule)
      },{
        path: 'steps',
        loadChildren: () => import('./stepsData/stepsData.module').then(m => m.StepsDataModule)
      },{
        path: 'library',
        loadChildren: () => import('./library/library.module').then(m => m.LibraryModule)
      },{
        path: 'video',
        loadChildren: () => import('./video/video.module').then(m => m.VideoModule)
      },{
        path: 'emailTemplate',
        loadChildren: () => import('./emailTemplate/emailTemplate.module').then(m => m.EmailTemplateModule)
      },{
        path: 'translations',
        loadChildren: () => import('./translation/translation.module').then(m => m.TranslationModule)
      },{
        path: 'achievements',
        loadChildren: () => import('./achievements/achievements.module').then(m => m.AchievementsModule)
      },{
        path: 'priceTable',
        loadChildren: () => import('./pricetable/pricetable.module').then(m => m.PricetableModule)
      },{
        path: 'scholarship',
        loadChildren: () => import('./scholarship/scholarship.module').then(m => m.ScholarshipModule)
      },{
        path: 'registerLinks',
        loadChildren: () => import('./registerLinks/registerLinks.module').then(m => m.RegisterLinksModule)
      },{
        path: 'staticCalendar',
        loadChildren: () => import('./staticCalendar/staticCalendar.module').then(m => m.StaticCalendarModule)
      },{
        path: 'module',
        loadChildren: () => import('./module/module.module').then(m => m.ModuleModule)
      },{
        path: 'chatSupport',
        loadChildren: () => import('./chatSupport/chatSupport.module').then(m => m.ChatSupportModule)
      },{
        path: 'bank',
        loadChildren: () => import('./bankData/bankData.module').then(m => m.BankDataModule)
      },{
        path: 'flashcards',
        loadChildren: () => import('./flashcards/flashcards.module').then(m => m.FlashcardsModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class MagnamentRoutingModule {}
