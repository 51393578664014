import {Component, ViewEncapsulation} from '@angular/core';
import {Shared} from "../shared/shared";
import {LoginService} from "../../service/LoginService";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: 'login.html',
  styleUrls: ['login.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Login {

  email: string;
  password: string;

  isSuccess: boolean = false;
  isError: boolean = false;

  constructor(private loginService: LoginService ,private shared: Shared, private router: Router) {
  }

  ngOnInit() {
    if(this.router.url.indexOf('/logout')!=-1){
      localStorage.removeItem('CURRENT_USER');
      localStorage.removeItem('CURRENT_CORP');
      localStorage.removeItem('CURRENT_SUBS_NUMBER');
    }
  }

  login(){
    this.loginService.login(this.email, this.password).subscribe(x=>{
      if(x!=null){
        this.isSuccess = x['success'];
        if(this.isSuccess) {
          let userData = JSON.parse(x['authentication']['principal']['username']);
          console.log(userData);
          this.shared.afterLogin(userData.corpId, userData.id);
        }else{
          this.isError = true;
        }
      }
    });
  }

}
