import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {PricetableEntity} from "../model/PricetableEntity";
import {ChatRoomEntity} from "../model/ChatRoomEntity";
import {ChatMessageEntity} from "../model/ChatMessageEntity";
import {MultisubscriptionEntity} from "../model/MultisubscriptionEntity";
import {BasicDto} from "../dto/BasicDto";
import {JustReferenceDto} from "../dto/JustReferenceDto";
import {ImageEntity} from "../model/ImageEntity";
import {ForumEntity} from "../model/ForumEntity";
import {ForumMessageEntity} from "../model/ForumMessageEntity";
import {ForumCategoriesEntity} from "../model/ForumCategoriesEntity";

@Injectable()
export class ForumService extends ParentService{

  public saveForum(forumEntity){
    return this.http.post<ForumEntity>(this.shared.MAGNAMENT_URL+'/saveForum', forumEntity)
  }

  public getForums(){
    return this.http.get<ForumEntity[]>(this.shared.MAGNAMENT_URL+'/getForums/'+this.shared.CORP_ID)
  }

  public saveForumMessage(forumEntity){
    return this.http.post<ForumMessageEntity>(this.shared.MAGNAMENT_URL+'/saveForumMessage', forumEntity)
  }

  public saveForumMessageWithoutNotification(forumEntity){
    return this.http.post<ForumMessageEntity>(this.shared.MAGNAMENT_URL+'/saveForumMessageWithoutNotification', forumEntity)
  }

  public getForumMessages(forumId, searchText){
    return this.http.get<ForumMessageEntity[]>(this.shared.MAGNAMENT_URL+'/getForumMessages/'+forumId+'/'+searchText)
  }

  public getSingleThread(threadId){
    return this.http.get<ForumMessageEntity[]>(this.shared.MAGNAMENT_URL+'/getSingleThread/'+threadId)
  }

  public getForumMessagesSorted(forumId, searchText){
    return this.http.get<ForumMessageEntity[]>(this.shared.MAGNAMENT_URL+'/getForumMessagesSorted/'+forumId+'/'+searchText)
  }

  public getForumCategories(forumId){
    return this.http.get<ForumCategoriesEntity[]>(this.shared.MAGNAMENT_URL+'/getForumCategories/'+forumId)
  }

  public uploadFileAudio(file) {
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post<JustReferenceDto>(this.shared.MAGNAMENT_URL+'/uploadAudio', formData);
  }

}
