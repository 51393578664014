import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {FlashcardEntity} from "../model/FlashcardEntity";
import {JustReferenceDto} from "../dto/JustReferenceDto";

@Injectable()
export class FlashcardService extends ParentService{

  public async getFlashcards() {
    const corps = await this.shared.getCorpGroups();
    return this.http.get<FlashcardEntity[]>(this.shared.MAGNAMENT_URL + '/getFlashcards/' + corps);
  }

  public getFlashcard(reference){
    return this.http.get<FlashcardEntity>(this.shared.MAGNAMENT_URL+'/getFlashcard/'+reference);
  }

  public searchFlashcards(searchFlashcards){
    return this.http.post<FlashcardEntity[]>(this.shared.MAGNAMENT_URL+'/searchFlashcards', searchFlashcards);
  }

  public uploadFile(file) {
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post<JustReferenceDto>(this.shared.MAGNAMENT_URL+'/uploadFlashcardMultimedia', formData);
  }

  public saveFlashcard(fce){
    console.log(fce)
    return this.http.post<FlashcardEntity>(this.shared.MAGNAMENT_URL+'/saveFlashcard', fce);
  }
}
