import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {LibraryFolderEntity} from "../model/LibraryFolderEntity";
import {LibraryEntity} from "../model/LibraryEntity";
import {HttpHeaders} from "@angular/common/http";
import {JustReferenceDto} from "../dto/JustReferenceDto";

@Injectable()
export class LibraryService extends ParentService{

  public getLibraryFolders(obj){
    return this.http.post<LibraryFolderEntity[]>(this.shared.MAGNAMENT_URL+'/getLibraryFolders', obj);
  }

  public getLibraryFolder(corpId){
    return this.http.get<LibraryFolderEntity[]>(this.shared.MAGNAMENT_URL+'/getLibraryFolders/'+corpId);
  }

  public getDocuments(libFolderId){
    return this.http.get<LibraryEntity[]>(this.shared.MAGNAMENT_URL+'/getDocuments/'+libFolderId);
  }

  public saveLibraryFolder(folder){
    return this.http.post<LibraryFolderEntity>(this.shared.MAGNAMENT_URL+'/saveLibraryFolder/',folder);
  }

  public saveLibrary(library){
    return this.http.post<LibraryEntity>(this.shared.MAGNAMENT_URL+'/saveLibrary/',library);
  }

  public getLibrary(reference){
    return this.http.get<LibraryEntity>(this.shared.MAGNAMENT_URL+'/getLibrary/'+reference);
  }

  public uploadDocument(file){
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post<JustReferenceDto>(this.shared.MAGNAMENT_URL+'/uploadDocument', formData);
  }

}
