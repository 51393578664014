
export class SearchSubcriptionDto {
  corpId: number;
  course: number[];
  courseType: number[];
  enabled: boolean;
  testSubscription: boolean;
  scholarshipSent: boolean;
  comments: string;
  endDateF: string;
  endDateT: string;
  id: number;
  modality: number[];
  reference: string;
  site: number[];
  startDateF: string;
  startDateT: string;
  studentId: number;
  turn: number[];
  workgroup: number[];
  tutor: number[];
  scholarship: number[];
  disabledReason: number[];

  courseSearchType: boolean = false;
  courseTypeSearchType: boolean = false;
  modalitySearchType: boolean = false;
  siteSearchType: boolean = false;
  turnSearchType: boolean = false;
}
