import {BasicDto} from "../dto/BasicDto";
import {SubscriptionSettingEntity} from "./SubscriptionSettingEntity";

export class SubscriptionEntity {
  corpId: number;
  course: BasicDto;
  courseType: BasicDto;
  enabled: boolean;
  testSubscription: boolean;
  comments: string;
  endDate: string;
  id: number;
  modality: BasicDto;
  reference: string;
  site: BasicDto;
  startDate: string;
  studentId: number;
  turn: BasicDto;
  settings: SubscriptionSettingEntity;
  workgroup: number[];
  tutor: number[];
  scholarship: number[];
  staticCalendarId: number[];
  permissionRoleId: number;
  disabledReason: number;

  created: string;
  updated: string;

  constructor() {
  }
}
