import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {LibraryFolderEntity} from "../model/LibraryFolderEntity";
import {LibraryEntity} from "../model/LibraryEntity";
import {VideoEntity} from "../model/VideoEntity";
import {VideoFolderEntity} from "../model/VideoFolderEntity";
import {VideoCommentEntity} from "../model/VideoCommentEntity";
import {AchievementEntity} from "../model/AchievementEntity";

@Injectable()
export class AchievementService extends ParentService{

  public getAchievement(){
      return this.http.get<AchievementEntity[]>(this.shared.MAGNAMENT_URL+'/getAchievement');
  }

}
