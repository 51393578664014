import {Component, ElementRef, ViewChild} from '@angular/core';
import {Shared} from "../../shared/shared";
import {UserService} from "../../../service/UserService";
import {StudentEntity} from "../../../model/StudentEntity";
import {SubscriptionEntity} from "../../../model/SubscriptionEntity";

import * as moment from "moment";

// import Swiper JS
import Swiper, {Navigation, Pagination} from 'swiper';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {BasicDto} from "../../../dto/BasicDto";
import {SubscriptionPaymentsEntity} from "../../../model/SubscriptionPaymentsEntity";
import {StatePopover} from "./statePopover/statePopover";
import {TypePopover} from "./typePopover/typePopover";
import {CommonUserDto} from "../../../dto/CommonUserDto";
import {CalendarDto} from "../../../dto/CalendarDto";
import {CalendarComponent} from "ionic2-calendar";
import {CalendarComponent as MyCalendarComponent} from "../generic/table/calendarComponent/calendarComponent";
import {CalendarComponent2 as MyCalendarComponent2} from "../generic/table/calendarComponent2/calendarComponent2";
import {CalendarMode, Step} from "ionic2-calendar/calendar";
import {CalendarService} from "../../../service/CalendarService";
import {SearchSubcriptionDto} from "../../../dto/SearchSubcriptionDto";
import {SearchUserObject} from "../../../dto/SearchUserObject";
import {HomologationStudentEntity} from "../../../model/HomologationStudent";
import {HomologationStudentRequestEntity} from "../../../model/HomologationStudentRequest";
import {HomologationsEntity} from "../../../model/HomologationsEntity";
import {HomologationRequestEntity} from "../../../model/HomologationRequest";
import {NotificationEntity} from "../../../model/NotificationEntity";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {VideoService} from "../../../service/VideoService";
import {LibraryService} from "../../../service/LibraryService";
import {ExamService} from "../../../service/ExamService";
import {VideoFolderEntity} from "../../../model/VideoFolderEntity";
import {LibraryFolderEntity} from "../../../model/LibraryFolderEntity";
import {ExamSettingEntity} from "../../../model/ExamSettingEntity";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CouponEntity} from "../../../model/CouponEntity";
import {TextComponent} from "../generic/table/textComponent/textComponent";
import {Payment} from "../../../model/Payment";
import {ButtonComponent} from "../generic/table/buttonComponent/buttonComponent";
import {StaticCalendarEntity} from "../../../model/StaticCalendarEntity";
import {EmailDto} from "../../../dto/EmailDto";
import {Angular5Csv} from "angular5-csv/dist/Angular5-csv";
import {DatePipe} from "@angular/common";
import {SubscriptionStatisticsDto} from "../../../dto/SubscriptionStatisticsDto";
import {CheckDataDto} from "../../../dto/CheckDataDto";
import {SearchPaymentsDto} from "../../../dto/SearchPaymentsDto";
import {FormatPopover} from "./formatPopover/formatPopover";
import {DownloadStudentSettingDto} from "../../../dto/DownloadStudentSettingDto";
import {AlertController} from "@ionic/angular";
import {SubscriptionScholarshipEntity} from "../../../model/SubscriptionScholarshipEntity";
import {SearchStudentDto} from "../../../dto/SearchStudentDto";

const swiper = new Swiper('.swiper', {
  // configure Swiper to use modules
  modules: [Navigation, Pagination],
});

@Component({
  selector: 'app-users',
  templateUrl: 'users.html',
  styleUrls: ['users.scss'],
  providers: [DatePipe]
})
export class Users {

  students: StudentEntity[];
  current: StudentEntity;
  currentSub: SubscriptionEntity;
  subscriptionsPayments: SubscriptionPaymentsEntity;

  subscriptionsStatistics: SubscriptionStatisticsDto[];
  subscriptionsStatisticsExams: SubscriptionStatisticsDto[];
  subscriptionsStatisticsLibraries: SubscriptionStatisticsDto[];
  subscriptionsStatisticsVideos: SubscriptionStatisticsDto[];

  eResult = 0;
  ePercent = 0;
  lPercent = 0;
  vPercent = 0;

  subscriptions_export: SubscriptionEntity[];
  subscriptionsPayments_export: SubscriptionPaymentsEntity;

  newSubPaymentEntity: Payment;

  subscriptions: SubscriptionEntity[];
  subscriptionsActive: SubscriptionEntity[];
  subscriptionsInactive: SubscriptionEntity[];

  staticCalendars: StaticCalendarEntity[];

  basicData: BasicDto[];
  commonUserData: CommonUserDto;

  searchUser: SearchStudentDto = new SearchStudentDto();
  searchSubscription: SearchSubcriptionDto = new SearchSubcriptionDto();
  searchSubscriptionPayments: SubscriptionPaymentsEntity = new SubscriptionPaymentsEntity();
  searchPayments: SearchPaymentsDto = new SearchPaymentsDto();

  homologations: HomologationsEntity[] = [];
  homologationsRequest: HomologationRequestEntity[] = [];
  homologationStudent: HomologationStudentEntity[] = [];
  homologationStudentRequest: HomologationStudentRequestEntity[] = [];

  currentTab = 1;
  currentSubsTab = 1;
  currectHomologationSegmentSelected = 1;

  showImageMaxi = false;
  newStudentEmail = "";

  courseIndex: number;
  courseTypeIndex: number;
  modalityIndex: number;
  siteIndex: number;

  coursesSearch: BasicDto[] = [];
  coursesTypeSearch: BasicDto[] = [];
  modalitySearch: BasicDto[] = [];
  siteSearch: BasicDto[] = [];
  turnSearch: BasicDto[] = [];
  countries: BasicDto[] = [];

  showCourseType: boolean = false;
  showModality: boolean = false;
  showSite: boolean = false;
  showTurn: boolean = false;

  showSubStartDate: boolean = false;
  showSubEndDate: boolean = false;

  showNotStartDate: boolean = false;
  showNotEndDate: boolean = false;

  showSubStartDateSF: boolean = false;
  showSubStartDateST: boolean = false;
  showSubEndDateSF: boolean = false;
  showSubEndDateST: boolean = false;

  PshowSubStartDateSF: boolean = false;
  PshowSubStartDateST: boolean = false;
  PshowSubEndDateSF: boolean = false;
  PshowSubEndDateST: boolean = false;

  PshowDoneDateSF: boolean = false;
  PshowDoneDateST: boolean = false;
  PshowRestoreDateSF: boolean = false;
  PshowRestoreDateST: boolean = false;

  showSubFirstMonthlyDate: boolean = false;

  showSubPaymentStartDate: boolean = false;
  showSubPaymentEndDate: boolean = false;

  availableVideos: VideoFolderEntity[];
  availableLibraries: LibraryFolderEntity[];
  availableExams: ExamSettingEntity[];

  newNotification: NotificationEntity;
  newEmail: EmailDto;
  emailAttachments: string[] = [];
  emailTemplates: any;
  showEmails: boolean = false;

  isCheckingEmail: number = 0;
  isCheckingNif: number = 0;
  isDownloading = false;

  subscriptionsNotificationsSelected: number[];
  subscriptionsNotificationsFiltered: SubscriptionEntity[];
  subscriptionsNotifications: SubscriptionEntity[];
  subscriptionsScholarship: SubscriptionScholarshipEntity[];
  currentSubscriptionScholarship : SubscriptionScholarshipEntity;

  rightDiv: number = 1;
  openEmojiSubject: boolean = false;
  openEmojiMessage: boolean = false;
  caretPos: number = 0;
  selectedFile: any;

  showVideos: boolean = false;
  showExams: boolean = false;
  showDocuments: boolean = false;

  coupons: CouponEntity[];
  modeAdd: boolean = false;
  showTable: boolean = true;

  totalPaid: number = 0;
  totalLeft: number = 0;

  currentPaymentCurrency = 0;
  handlerMessage = '';
  savedPayment = null;

  dssd: DownloadStudentSettingDto = new DownloadStudentSettingDto();

  homologationStatus = [
    {id: 1, name: 'Document request'},
    {id: 3, name: 'Document sent'},
    {id: 5, name: 'Document validate'},
    {id: 7, name: 'Send to ministry'},
    {id: 9, name: 'Aprovved by ministry'},
  ];

  homologationRequestStatus = [
    {id: 0, name: 'Sent'},
    {id: 1, name: 'Validated'},
    {id: 2, name: 'Rejected'},
  ];

  typeOfNotifications = [
    {id: 0, name: 'Text'},
    {id: 1, name: 'External URL'},
    {id: 2, name: 'Class'},
    {id: 3, name: 'Tutorship'},
    {id: 4, name: 'Exam'},
    {id: 5, name: 'Video'},
    {id: 6, name: 'Document'},
    {id: 7, name: 'Internal URL'},
  ];

  typeOfPayments = [
    {id: 0, name: 'Enrollment'},
    {id: 1, name: 'Monthly'},
    {id: 2, name: 'Totality'},
    {id: 3, name: 'Manuals'},
    {id: 4, name: 'Repeat rate'},
    {id: 5, name: 'Homologation rate'},
  ];

  formatOfPayments = [
    {id: 0, name: 'Paypal'},
    {id: 1, name: 'Redsys'},
    {id: 2, name: 'Stripe'},
    {id: 3, name: 'Bank transfer'},
    {id: 4, name: 'Bizum'},
    {id: 5, name: 'Cash'},
  ];

  currencies = [
    {id: 'EUR', name: 'EUR'},
    {id: 'USD', name: 'USD'},
    {id: 'MXN', name: 'MXN'},
    {id: 'ARS', name: 'ARS'},
    {id: 'CRC', name: 'CRC'},
  ];

  @ViewChild('myCalendar') myCalendar: CalendarComponent;

  genericEvent;
  genericStartDate;
  genericEndDate;

  eventSource;
  viewTitle;
  language = "en_US";

  headers: any = {
    columns: {
      name: {title: 'Name', hide: false, filter: false},
      surname: {title: 'Surname', hide: false, filter: false},
      email: {title: 'Email', hide: false, filter: false},
      mobile: {title: 'Mobile', hide: false, filter: false},

      nif: {title: 'Nif', hide: true, filter: false},
      address: {title: 'Address', hide: true, filter: false},
      corpId: {title: 'Corp ID', hide: true, filter: false},
      country: {title: 'Country', hide: true, filter: false},
      cp: {title: 'Cp', hide: true, filter: false},
      id: {title: 'Id', hide: true, filter: false},
      password: {title: 'Password', hide: true, filter: false},
      privacy1: {title: 'Privacy1', hide: true, filter: false},
      privacy2: {title: 'Privacy2', hide: true, filter: false},
      profileImage: {title: 'ProfileImage', hide: true, filter: false},
      province: {title: 'Province', hide: true, filter: false},
      reference: {title: 'Reference', hide: true, filter: false},
      town: {title: 'Town', hide: true, filter: false}
    },
    actions: false,
  }

  headersPayments: any = {
    columns: {
      studentId: {title: 'Student id', hide: true, filter: false},
      subscriptionId: {title: 'Sub id', hide: true, filter: false},
      subscriptionPaymentId: {title: 'Sub payment id', hide: true, filter: false},

      typeOfPayment: {
        title: 'Type', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (data) => {
          return data;
        },
        renderComponent: TypePopover,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.typeSave.subscribe(data => {
              this.savePaymentData(data, false);
            });
          }
        },
      },
      price: {
        title: 'Price', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let payment = this.subscriptionsPayments.payments.find(x => x.id == data.row.id)
              payment.price = data.text.replace(',','.');
              this.savePaymentData(payment, false);
            });
          }
        },
      },
      startDate: {
        title: 'Start date', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: MyCalendarComponent2,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let payment = this.subscriptionsPayments.payments.find(x => x.id == data.row.id)
              payment.startDate = data.date;
              this.savePaymentData(payment, false);
            });
          }
        },
      },
      endDate: {
        title: 'End date', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: MyCalendarComponent2,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeCalendar.subscribe(data => {
              let payment = this.subscriptionsPayments.payments.find(x => x.id == data.row.id)
              payment.endDate = data.date;
              this.savePaymentData(payment, false);
            });
          }
        },
      },
      doneDate: {
        title: 'Done date', hide: false, filter: true, type: "html",
        valuePrepareFunction: (cell, row) => {
          return this.convertDate(cell);
        }
      },
      restoreDate: {
        title: 'Restore date', hide: false, filter: true, type: "html",
        valuePrepareFunction: (cell, row) => {
          return this.convertDate(cell);
        }
      },
      formatOfPayment: {
        title: 'Format', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (data) => {
          return data;
        },
        renderComponent: FormatPopover,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.formatSave.subscribe(data => {
              this.savePaymentData(data, false);
            });
          }
        },
      },
      done: {
        title: 'State', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {
          if (!row.done && !row.restore) {
            var inputObj = {
              value: 1,
              paidDate: row.paidDate,
              restoreDate: row.restoreDate
            };
            return JSON.stringify(inputObj);
          } else if (row.done && !row.restore) {
            var inputObj = {
              value: 2,
              paidDate: row.paidDate,
              restoreDate: row.restoreDate
            };
            return JSON.stringify(inputObj);
          } else if (row.restore) {
            var inputObj = {
              value: 3,
              paidDate: row.paidDate,
              restoreDate: row.restoreDate
            };
            return JSON.stringify(inputObj);
          }
        },
        renderComponent: StatePopover,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.stateSave.subscribe(data => {
              this.savePaymentData(data, false);
            });
          }
        },
      },
      restore: {title: 'Restore', hide: true, filter: false},
      currecy: {title: 'Currency', hide: true, filter: false},
      corpId: {
        title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {
          return {cell: row.id, icon: 'trash-outline', text: '', color: ''}
        },
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.deletePaymentData(data);
            });
          }
        },
      },
      id: {
        title: '', hide: false, filter: false, type: "custom",
        valuePrepareFunction: (cell, row) => {
          if(row.fileUrl == null) {
            return {cell: row.id, icon: 'cloud-upload-outline', text: '', color: ''}
          }else{
            return {cell: row.id, icon: 'eye-outline', text: '', color: ''}
          }
        },
        renderComponent: ButtonComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeButton.subscribe(data => {
              this.savedPayment = data;
              this.checkPayment(false);
            });
            instance.changeContextButton.subscribe(data => {
              this.savedPayment = data;
              this.checkPayment(true);
            });
          }
        },
      },
    },
    actions: false,
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: true,
    placeholder: 'Enter text here...',
    fonts: [
      {class: 'arial', name: 'Arial'},
    ],
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['indent', 'outdent', 'superscript', 'subscript', 'link', 'unlink', 'insertImage', 'insertVideo', 'strikeThrough', 'fontName'],
    ]
  };

  @ViewChild('fileButton') fileButton: ElementRef;
  @ViewChild('fileButton3') fileButton3: ElementRef;

  constructor(private userService: UserService, private videoService: VideoService, private datePipe: DatePipe, private modalService: NgbModal, private libraryService: LibraryService, private examService: ExamService, private calendarService: CalendarService, private shared: Shared, private alertController: AlertController) {
  }

  ngOnInit() {
    this.myInit();
  }

  async myInit(){
    (await this.userService.getStudents()).subscribe(async x => {
      if (x != null) {
        this.students = x;
        (await this.userService.getBasicDataByCorp()).subscribe(async p => {
          if (p != null) {
            this.basicData = p;

            (await this.userService.getCommonUserData()).subscribe(t => {
              if (t != null) {
                this.commonUserData = t;
              }
            });

            this.userService.getCountries().subscribe(y => {
              if (y != null) {
                this.countries = y;
              }
            });

            this.userService.getCoupons().subscribe(z => {
              if (z != null) {
                this.coupons = z;
              }
            });

            this.coursesSearch = this.basicData;
            this.coursesSearch.map(x => x.list.map(y => {
              y.name = y.name + ' (' + x.alias + ')';
              this.coursesTypeSearch.push(y)
            }));
            this.coursesSearch.map(t => t.list.map(x => x.list.map(y => {
              y.name = y.name + ' (' + x.alias + '-' + t.alias + ')';
              this.modalitySearch.push(y)
            })));
            this.coursesSearch.map(p => p.list.map(t => t.list.map(x => x.list.map(y => {
              y.name = y.name + ' (' + x.alias + '-' + t.alias + '-' + p.alias + ')';
              this.siteSearch.push(y)
            }))));
            this.coursesSearch.map(r => r.list.map(p => p.list.map(t => t.list.map(x => x.list.map(y => {
              y.name = y.name + ' (' + x.alias + '-' + t.alias + '-' + p.alias + '-' + r.alias + ')';
              this.turnSearch.push(y)
            })))));
          }
        });
      }
    });
    if(localStorage.getItem('DOWNLOAD_USER_SETTINGS') != null) {
      this.dssd = JSON.parse(localStorage.getItem('DOWNLOAD_USER_SETTINGS'));
    }
  }

  newSubPayment() {
    this.newSubPaymentEntity = new Payment();
    this.modeAdd = true;
  }

  sendEmail() {
    this.userService.sendEmail(this.newEmail).subscribe();
    this.rightDiv = 0;
  }

  sendNotification() {
    this.newNotification.corpId = this.shared.CORP_ID;
    this.newNotification.subscriptionId = this.subscriptionsNotificationsSelected;
    this.userService.sendNotification(this.newNotification).subscribe();
    this.rightDiv = 0;
  }

  generateCalendar() {

  }

  generateCalendarSub() {
    this.userService.generateCalendarSub(this.currentSub.reference).subscribe(x => {
      if (x != null) {
        console.log("Loading");
        this.getCalendar(this.currentSub.reference);
      }
    });
  }

  showEmail() {
    this.rightDiv = 2;
    this.userService.getEmailTemplates().subscribe(x => {
      if (x != null) {
        this.emailTemplates = x;
      }
    });
    this.newEmail = null;
    this.emailAttachments = [];
    this.newEmail = new EmailDto();
    this.newEmail.corpId = this.shared.CORP_ID;
    this.newEmail.studentId = this.students.map(x => x.id);
    this.newEmail.studentEmail = this.students.map(x => x.email);
    this.newEmail.from = this.shared.CURRENT_CORP.magnamentEmail;
    this.newEmail.subject = "";
    this.newEmail.message = "";
    this.newEmail.attch = this.emailAttachments;
  }

  showEmailOneEmail() {
    this.rightDiv = 2;
    this.userService.getEmailTemplates().subscribe(x => {
      if (x != null) {
        this.emailTemplates = x;
      }
    });
    this.newEmail = null;
    this.emailAttachments = [];
    this.newEmail = new EmailDto();
    this.newEmail.corpId = this.shared.CORP_ID;
    this.newEmail.studentId = [this.current.id];
    this.newEmail.studentEmail = [this.current.email];
    this.newEmail.from = this.shared.CURRENT_CORP.magnamentEmail;
    this.newEmail.subject = "";
    this.newEmail.message = "";
    this.newEmail.attch = this.emailAttachments;
  }

  gotoUserPage(){
    if(this.current.corpId == 1) {
      this.shared.goExternalTo('http://campus.mediplus.es/#/tabs/home/'+btoa(this.current.id+""))
    }else if(this.current.corpId == 2){
      this.shared.goExternalTo('https://campus.medipluscostarica.com/#/tabs/home/'+btoa(this.current.id+""))
    }else if(this.current.corpId == 3){
      this.shared.goExternalTo('http://campus.mediplusargentina.com/#/tabs/home/'+btoa(this.current.id+""))
    }else if(this.current.corpId == 4){
      this.shared.goExternalTo('http://localhost:8100/#/tabs/home/'+btoa(this.current.id+""))
    }else if(this.current.corpId == 5){
      this.shared.goExternalTo('http://campus.mediplusmexico.com/#/tabs/home/'+btoa(this.current.id+""))
    }
  }

  deleteFromList(em) {
    this.newEmail.studentEmail = this.newEmail.studentEmail.filter(x => em != x);
  }

  showNotification() {
    this.rightDiv = 3;
    this.newNotification = new NotificationEntity();

    this.userService.getSubscriptionsNotifications(this.students.map(x => x.id)).subscribe(x => {
      if (x != null) {
        this.subscriptionsNotificationsSelected = x.map(t => t.id);
        this.subscriptionsNotificationsFiltered = x;
        this.subscriptionsNotifications = x;
      }
    });

    let obj = {
      'courseId': null,
      'courseTypeId': null,
      'modalityId': null,
      'siteId': null,
      'turnId': null
    }
    this.videoService.getVideoFolders(obj).subscribe(x => {
      if (x != null) {
        this.availableVideos = x;
      }
    });

    this.libraryService.getLibraryFolders(obj).subscribe(x => {
      if (x != null) {
        this.availableLibraries = x;
      }
    });

    this.examService.getExamsSettings(obj).subscribe(x => {
      if (x != null) {
        this.availableExams = x;
      }
    });
  }

  openModal(template, type) {
    this.modalService.open(template, {centered: true});
    this.showVideos = false;
    this.showExams = false;
    this.showDocuments = false;
    if (type == 0) {
      this.showVideos = true;
    } else if (type == 1) {
      this.showDocuments = true;
    } else if (type == 2) {
      this.showExams = true;
    }
  }

  checkEmail() {
    this.isCheckingEmail = 3;
    if (this.current.email != null && this.current.email.length > 3) {

      if (this.current.reference != null && this.current.reference != undefined) {
        this.userService.checkEmailByReference(this.current.email, this.current.reference).subscribe((x: CheckDataDto) => {
          if (x.status) {
            this.current.id = x.studentId;
            this.isCheckingEmail = 2;
          } else {
            this.isCheckingEmail = 1;
          }
        });
      } else {
        this.userService.checkEmail(this.current.email).subscribe((x: CheckDataDto) => {
          if (x.status) {
            this.current.id = x.studentId;
            this.isCheckingEmail = 2;
          } else {
            this.isCheckingEmail = 1;
          }
        });
      }
    } else {
      this.isCheckingEmail = 0;
    }
  }

  checkNif() {
    this.isCheckingNif = 3;
    if (this.current.nif != null && this.current.nif.length > 3) {

      if (this.current.reference != null && this.current.reference != undefined) {
        this.userService.checkNifByReference(this.current.nif, this.current.reference).subscribe((x: CheckDataDto) => {
          if (x.status) {
            this.current.id = x.studentId;
            this.isCheckingNif = 2;
          } else {
            this.isCheckingNif = 1;
          }
        });
      } else {
        this.userService.checkNif(this.current.nif).subscribe((x: CheckDataDto) => {
          if (x.status) {
            this.current.id = x.studentId;
            this.isCheckingNif = 2;
          } else {
            this.isCheckingNif = 1;
          }
        });
      }
    } else {
      this.isCheckingNif = 0;
    }
  }

  templateSelected(ev) {
    this.newEmail.message = ev.template;
    this.newEmail.subject = ev.subject;
  }

  addEmojiSubject(evt) {
    this.newEmail.subject = [
      this.newEmail.subject.slice(0, this.caretPos),
      evt.emoji.native,
      this.newEmail.subject.slice(this.caretPos)].join('');
  }

  addEmojiMessage(evt) {
    this.newEmail.message = [
      this.newEmail.message.slice(0, this.caretPos),
      evt.emoji.native,
      this.newEmail.message.slice(this.caretPos)].join('');
  }

  getCaretPos(evt) {
    if (evt.srcElement.selectionStart || evt.srcElement.selectionStart == '0') {
      this.caretPos = evt.srcElement.selectionStart;
    }
  }

  selFile(evt) {
    this.selectedFile = evt.target.files[0];

    this.userService.uploadAttachment(this.selectedFile).subscribe(x => {
      if (x != null) {
        this.emailAttachments.push(x.reference);
        evt.target.files = null;
      }
    });
  }

  selFile2(evt) {
    this.selectedFile = evt.target.files[0];

    this.userService.uploadPaymentFile(this.selectedFile).subscribe(x => {
      if (x != null) {
        evt.target.files = null;
        this.attachPaymentData(x.reference);
      }
    });
  }

  selFile3(evt) {
    this.selectedFile = evt.target.files[0];

    this.userService.uploadScholarshipFile(this.selectedFile).subscribe(x => {
      if (x != null) {
        evt.target.files = null;
        this.attachDocumentSubscriptionScholarship(x.reference);
      }
    });
  }

  toogleSubscriptionsNotifications(subId) {
    if (this.subscriptionsNotificationsSelected.find(x => x == subId)) {
      this.subscriptionsNotificationsSelected = this.subscriptionsNotificationsSelected.filter(t => t != subId);
    } else {
      this.subscriptionsNotificationsSelected.push(subId);
    }
  }

  checkIsSelected(subId) {
    return this.subscriptionsNotificationsSelected.find(x => x == subId)
  }

  getFileName(url) {
    return url.substring(url.lastIndexOf('\\') + 1, url.length);
  }

  deleteAttachmentsIndex(item) {
    this.emailAttachments = this.emailAttachments.filter(x => x != item);
  }

  getUserHomologations() {
    this.userService.getUserHomologations(this.current.reference).subscribe(x => {
      if (x != null) {
        this.homologationStudent = x;
        if (this.homologationStudent != null && this.homologationStudent.length > 0) {
          this.getUserRequestHomologations(this.homologationStudent[0]);
        }
      }
    });
  }

  getSubscriptionScholarships() {
    this.userService.getSubscriptionScholarships(this.currentSub.reference).subscribe(x => {
      if (x != null) {
        this.subscriptionsScholarship = x;
      }
    });
  }

  getUserRequestHomologations(homologationStudent) {
    this.userService.getHomologations(homologationStudent.homologationId).subscribe(x => {
      if (x != null) {
        this.homologations = x;
        this.userService.getHomologationsRequest(homologationStudent.homologationId).subscribe(t => {
          if (t != null) {
            this.homologationsRequest = t;
          }
        });
      }
    });

    this.userService.getUserRequestHomologations(homologationStudent.id).subscribe(x => {
      if (x != null) {
        this.homologationStudentRequest = x;
      }
    });
  }

  saveHSRequest(hsRequest) {
    this.userService.saveHSRequest(hsRequest).subscribe();
  }

  saveHStudent(hStudent) {
    this.userService.saveHStudent(hStudent).subscribe();
  }

  saveStudent() {
    this.userService.saveStudent(this.current).subscribe(x => {
      if (x != null) {
        let indx = this.students.findIndex(t => t.id == x.id);
        if(indx != -1) {
          this.students[indx] = x;
        }else{
          this.students.unshift(x);
        }
        this.students = this.students.filter(y => y);
        this.current = null;
      }
    });
  }

  trimEmail(){
    if(this.current.email != null && this.current.email.length>3) {
      this.current.email = this.current.email.trim()
    }
  }

  cleanSearchsUsers() {
    this.searchUser = new SearchStudentDto();
    this.searchSubscription = new SearchSubcriptionDto();
    this.searchSubscriptionPayments = new SubscriptionPaymentsEntity();
    this.searchPayments = new SearchPaymentsDto();
  }

  async searchsUsers() {
    const groups = await this.shared.getCorpGroups();
    this.searchUser.corpId = groups.split(',').map(Number);
    let obj = new SearchUserObject(this.searchUser, this.searchSubscription, this.searchSubscriptionPayments, this.searchPayments);

    this.userService.searchStudents(obj).subscribe(x => {
      if (x != null) {
        this.students = x;
      }
    });
  }

  async rowSelectedEmitter(row: StudentEntity) {
    this.rightDiv = 1;
    this.current = row;
    this.current.password = "";
    this.currentSubsTab = 1;

    this.showCourseType = false;
    this.showModality = false;
    this.showSite = false;
    this.showTurn = false;

    this.subscriptionsPayments = null;
    this.currentSub = null;
    this.subscriptionsActive = null;
    this.subscriptionsInactive = null;
    this.subscriptions = null;

    this.userService.getSubscriptions(this.current.reference).subscribe(y => {
      if (y != null) {

        this.subscriptions = y;
        this.subscriptionsActive = y.filter(t => t.enabled);
        this.subscriptionsInactive = y.filter(t => !t.enabled);

        if (y.length > 0) {
          if (this.subscriptionsActive.length > 0) {
            this.currentSub = this.subscriptionsActive[0];
            this.getCalendar(this.currentSub.reference);
          } else if (this.subscriptionsInactive.length > 0) {
            this.currentSub = this.subscriptionsInactive[0];
            this.getCalendar(this.currentSub.reference);
          }
          this.userService.getSubscriptionsPayments(this.currentSub.reference).subscribe(w => {
            if (w != null) {
              this.subscriptionsPayments = w;
              if (this.subscriptionsPayments.currency == 'EUR') {
                this.currentPaymentCurrency = 1;
              } else if (this.subscriptionsPayments.currency == 'USD') {
                this.currentPaymentCurrency = 2;
              } else if (this.subscriptionsPayments.currency == 'MXN') {
                this.currentPaymentCurrency = 3;
              }

              this.totalPaid = 0;
              let discount = 0;
              if (this.subscriptionsPayments.discountPrice != null && this.subscriptionsPayments.discountPrice != 0) {
                discount = this.subscriptionsPayments.discountPrice;
              }
              if (this.subscriptionsPayments.discountRate != null && this.subscriptionsPayments.discountRate != 0) {
                discount = (this.subscriptionsPayments.coursePrice * this.subscriptionsPayments.discountRate) / 100;
              }

              this.subscriptionsPayments.payments.map(t => {
                if (t.done && !t.restore) {
                  if (t.typeOfPayment != 'MANUALS' && t.typeOfPayment != 'REPEAT_RATE' && t.typeOfPayment != 'HOMOLOGATION_RATE') {
                    this.totalPaid = (this.totalPaid + t.price)
                  }
                }
              });
              this.totalLeft = (this.subscriptionsPayments.coursePrice - discount) - this.totalPaid;
            }
          });

          this.subscriptionsStatistics = null;
          this.userService.getSubscriptionsStatistics(this.currentSub.reference).subscribe(w => {
            if (w != null) {
              this.subscriptionsStatistics = w;
              this.subscriptionsStatisticsExams = this.subscriptionsStatistics.filter(x => x.type == 'EXAM');
              this.subscriptionsStatisticsLibraries = this.subscriptionsStatistics.filter(x => x.type == 'LIBRARY_FOLDER');
              this.subscriptionsStatisticsVideos = this.subscriptionsStatistics.filter(x => x.type == 'VIDEO_FOLDER');

              this.statistics();
            }
          });
        }

        if (this.currentSub != null) {
          if (this.currentSub.course != null && this.currentSub.course.id != -1) {
            this.changeCourse(false);
          } else {
            this.currentSub.course = new BasicDto(-1, '', '', null);
          }

          if (this.currentSub.courseType != null && this.currentSub.courseType.id != -1) {
            this.changeCourseType(false);
          } else {
            this.currentSub.courseType = new BasicDto(-1, '', '', null);
          }

          if (this.currentSub.modality != null && this.currentSub.modality.id != -1) {
            this.changeModality(false);
          } else {
            this.currentSub.modality = new BasicDto(-1, '', '', null);
          }

          if (this.currentSub.site != null && this.currentSub.site.id != -1) {
            this.changeSite(false);
          } else {
            this.currentSub.site = new BasicDto(-1, '', '', null);
          }

          if (this.currentSub.turn != null && this.currentSub.turn.id != -1) {
            this.changeTurn();
          } else {
            this.currentSub.turn = new BasicDto(-1, '', '', null);
          }

        }

        this.getUserHomologations();
        this.getSubscriptionScholarships();
      }
    });
  }

  statistics() {
    let r1 = 0;
    let r2 = 0;
    this.subscriptionsStatisticsExams.map(x1 => {
      r1 = r1 + x1.netResult;
      r2 = r2 + x1.percentajeResult;
    });
    this.eResult = Math.round(r1 / this.subscriptionsStatisticsExams.length);
    this.ePercent = Math.round(r2 / this.subscriptionsStatisticsExams.length);


    let s = 0;
    this.subscriptionsStatisticsVideos.map((x1, index) => {
      if (x1.list != null && x1.list.length > 0) {
        let s1 = 0;
        x1.list.forEach(x2 => {
          if (x2.list != null && x2.list.length > 0) {
            let s2 = 0;
            x2.list.forEach(x3 => {

              if (x3.list != null && x3.list.length > 0) {
                let s3 = 0;
                x3.list.forEach(x4 => {

                  if (x4.list != null && x4.list.length > 0) {
                    let s4 = 0;
                    x4.list.forEach(x5 => {
                      s4 = s4 + x5.percentajeResult;
                    });
                    x4.percentajeResult = Math.round(s4 / x4.list.length);
                  } else {
                    if (x4.percentajeResult == null) {
                      x4.percentajeResult = 0;
                    }
                  }
                  s3 = s3 + x4.percentajeResult;

                });
                x3.percentajeResult = Math.round(s3 / x3.list.length);
              } else {
                if (x3.percentajeResult == null) {
                  x3.percentajeResult = 0;
                }
              }
              s2 = s2 + x3.percentajeResult;

            });
            x2.percentajeResult = Math.round(s2 / x2.list.length);
          } else {
            if (x2.percentajeResult == null) {
              x2.percentajeResult = 0;
            }
          }
          s1 = s1 + x2.percentajeResult;
        });
        x1.percentajeResult = Math.round(s1 / x1.list.length);
      } else {
        if (x1.percentajeResult == null) {
          x1.percentajeResult = 0;
        }
      }
      s = s + x1.percentajeResult;
    });
    this.vPercent = Math.round(s / this.subscriptionsStatisticsVideos.length);


    let p = 0;
    this.subscriptionsStatisticsLibraries.map(x1 => {
      if (x1.list != null && x1.list.length > 0) {
        let s1 = 0;
        x1.list.forEach(x2 => {
          if (x2.list != null && x2.list.length > 0) {
            let s2 = 0;
            x2.list.forEach(x3 => {

              if (x3.list != null && x3.list.length > 0) {
                let s3 = 0;
                x3.list.forEach(x4 => {

                  if (x4.list != null && x4.list.length > 0) {
                    let s4 = 0;
                    x4.list.forEach(x5 => {
                      s4 = s4 + x5.percentajeResult;
                    });
                    x4.percentajeResult = Math.round(s4 / x4.list.length);
                  } else {
                    if (x4.percentajeResult == null) {
                      x4.percentajeResult = 0;
                    }
                  }
                  s3 = s3 + x4.percentajeResult;

                });
                x3.percentajeResult = Math.round(s3 / x3.list.length);
              } else {
                if (x3.percentajeResult == null) {
                  x3.percentajeResult = 0;
                }
              }
              s2 = s2 + x3.percentajeResult;

            });
            x2.percentajeResult = Math.round(s2 / x2.list.length);
          } else {
            if (x2.percentajeResult == null) {
              x2.percentajeResult = 0;
            }
          }
          s1 = s1 + x2.percentajeResult;
        });
        x1.percentajeResult = Math.round(s1 / x1.list.length);
      } else {
        if (x1.percentajeResult == null) {
          x1.percentajeResult = 0;
        }
      }
      p = p + x1.percentajeResult;
    });
    this.lPercent = Math.round(p / this.subscriptionsStatisticsLibraries.length);
  }

  getStudentFullName(id) {
    if (id != null) {
      return this.students.find(x => x.id === id).name + " " + this.students.find(x => x.id === id).surname;
    }
    return "";
  }

  saveStartDate(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.currentSub.startDate = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  saveEndDate(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.currentSub.endDate = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  saveNotStartDate(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.newNotification.startDate = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  saveNotEndDate(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.newNotification.endDate = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  saveStartDateSF(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchSubscription.startDateF = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  saveStartDateST(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchSubscription.startDateT = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  saveEndDateSF(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchSubscription.endDateF = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  saveEndDateST(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchSubscription.endDateT = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  savePStartDateSF(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchPayments.startDateF = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  savePStartDateST(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchPayments.startDateT = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  savePEndDateSF(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchPayments.endDateF = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  savePEndDateST(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchPayments.endDateT = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  savePDoneDateSF(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchPayments.doneDateF = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  savePRestoreDateSF(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchPayments.restoreDateF = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  savePDoneDateST(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchPayments.doneDateT = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  savePRestoreDateST(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.searchPayments.restoreDateT = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  saveFirstMonthlyDate(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.subscriptionsPayments.firstMonthDate = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  saveDate(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.subscriptionsPayments.firstMonthDate = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  saveSubPaymentStartDate(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.newSubPaymentEntity.startDate = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  saveSubPaymentEndDate(event) {
    let value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.newSubPaymentEntity.endDate = moment.tz(value, this.shared.CURRENT_CORP.timezone).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
  }

  convertDate(date) {
    if (date != null) {
      return moment(date).format('yyyy-MM-DD');
    } else {
      return "";
    }
  }

  closeDateTimePickers() {
    this.showSubStartDateSF = false;
    this.showSubStartDateST = false;
    this.showSubEndDateSF = false;
    this.showSubEndDateST = false;
  }

  deleteStartDate() {
    this.currentSub.startDate = null;
  }

  deleteEndDate() {
    this.currentSub.endDate = null;
  }

  deleteFirstMonthlyDate() {
    this.subscriptionsPayments.firstMonthDate = null;
  }


  newUser() {
    this.current = new StudentEntity();
    this.current.corpId = this.shared.CORP_ID;
    this.current.corpGroupId = this.shared.CURRENT_CORP.corpGroup;
    this.currentSub = null;
    this.subscriptionsPayments = null;
    this.rightDiv = 1;
  }

  newSubscription() {
    this.currentSub = new SubscriptionEntity();
    this.currentSubsTab = 2;
    this.currentSub.studentId = this.current.id;
    this.currentSub.corpId = this.shared.CORP_ID;
    this.currentSub.enabled = false;
    this.currentSub.testSubscription = false;
    this.currentSub.course = new BasicDto(-1, "", "", []);
    this.currentSub.courseType = new BasicDto(-1, "", "", []);
  }

  saveUser() {
  }

  saveSubscriptionPayments() {
    this.userService.saveSubscriptionPayment(this.subscriptionsPayments).subscribe();
    this.currentSub = null;
    this.current = null;
  }

  testCalendar() {
    this.userService.testCalendar(this.currentSub).subscribe();
  }

  saveSubscriptionData() {
    this.userService.saveSubscriptionData(this.currentSub).subscribe();
    this.currentSub = null;
    this.current = null;
  }

  generatePayments() {
    this.userService.saveSubscriptionPayment(this.subscriptionsPayments).subscribe(y => {
      this.userService.generatePayments(this.subscriptionsPayments).subscribe(x => {
        if (x != null) {
          this.subscriptionsPayments = x;
        }
      });
    });
  }

  async savePaymentData(data, bool) {
    this.userService.savePayment(data).subscribe(async x => {
      if (x != null && bool) {
        this.newSubPaymentEntity = x;
        this.subscriptionsPayments.payments.push(this.newSubPaymentEntity);

        this.subscriptionsPayments.payments.sort((a, b) => a.startDate.localeCompare(b.startDate));

        this.showTable = false;
        await this.delay(200);
        this.showTable = true;
      }
    });
  }

   saveNewPaymentData() {
    this.newSubPaymentEntity.corpId = this.shared.CORP_ID;
    this.newSubPaymentEntity.studentId = this.current.id;
    this.newSubPaymentEntity.subscriptionId = this.currentSub.id;
    this.newSubPaymentEntity.subscriptionPaymentId = this.subscriptionsPayments.id;
    this.newSubPaymentEntity.currency = this.subscriptionsPayments.currency;
    this.savePaymentData(this.newSubPaymentEntity, true);
    this.modeAdd = false;
  }

  async deletePaymentData(data) {
    let p = this.subscriptionsPayments.payments.find(x => x.id == data);
    delete this.subscriptionsPayments.payments[this.subscriptionsPayments.payments.findIndex(x => x.id == data)];
    this.userService.deletePayment(p).subscribe();

    this.subscriptionsPayments.payments.sort((a, b) => a.startDate.localeCompare(b.startDate));

    this.showTable = false;
    await this.delay(200);
    this.showTable = true;
  }

  checkPayment(bool) {
    let p = this.subscriptionsPayments.payments.find(x => x.id == this.savedPayment);
    if(p.fileUrl == null || bool) {
      this.fileButton.nativeElement.click();
    }else{
      window.open('https://assets.mediplus.es/'+p.fileUrl, '_blank');
    }
  }

  checkScholarship(p, bool) {
    this.currentSubscriptionScholarship = p;
    if(p.documentUrl == null || bool) {
      this.fileButton3.nativeElement.click();
    }else{
      window.open('https://assets.mediplus.es/'+p.documentUrl, '_blank');
    }
  }

  async attachPaymentData(fileUrl) {
    let p = this.subscriptionsPayments.payments.find(x => x.id == this.savedPayment);
    p.fileUrl = fileUrl;

    const alert = await this.alertController.create({
      header: 'Attach file',
      subHeader: p.fileUrl,
      buttons: [{
        text: 'Upload and save',
        role: 'save',
        handler: () => {
          this.savePaymentData(p, false);
        },
      }]
    });
    await alert.present();
  }

  async attachDocumentSubscriptionScholarship(fileUrl) {
    const self = this;

    const alert = await this.alertController.create({
      header: 'Attach file',
      subHeader: fileUrl,
      buttons: [{
        text: 'Upload and save',
        role: 'save',
        handler: () => {
          this.currentSubscriptionScholarship.documentUrl = fileUrl;
          this.userService.saveSubscriptionScholarship(this.currentSubscriptionScholarship).subscribe(x=>{
            if(x!=null){
              let index = self.subscriptionsScholarship.findIndex(t=> t.id == x.id);
              self.subscriptionsScholarship[index] = x;
            }
          });
        },
      }]
    });
    await alert.present();
  }

  async changeCurrentSub(sub) {
    this.currentSub = sub;
    this.subscriptionsPayments = null;
    this.getCalendar(this.currentSub.reference);

    this.getSubscriptionScholarships();

    this.userService.getSubscriptionsPayments(this.currentSub.reference).subscribe(w => {
      if (w != null) {
        this.subscriptionsPayments = w;
        if (this.subscriptionsPayments.currency == 'EUR') {
          this.currentPaymentCurrency = 1;
        } else if (this.subscriptionsPayments.currency == 'USD') {
          this.currentPaymentCurrency = 2;
        } else if (this.subscriptionsPayments.currency == 'MXN') {
          this.currentPaymentCurrency = 3;
        }

        this.totalPaid = 0;
        let discount = 0;
        if (this.subscriptionsPayments.discountPrice != null) {
          discount = this.subscriptionsPayments.discountPrice;
        }
        if (this.subscriptionsPayments.discountRate != null) {
          discount = (this.subscriptionsPayments.coursePrice * this.subscriptionsPayments.discountRate) / 100;
        }
        this.subscriptionsPayments.payments.map(t => {
          if (t.done && !t.restore) {
            if (t.typeOfPayment != 'MANUALS' && t.typeOfPayment != 'REPEAT_RATE' && t.typeOfPayment != 'HOMOLOGATION_RATE') {
              this.totalPaid = (this.totalPaid + t.price)
            }
          }
        });
        this.totalLeft = (this.subscriptionsPayments.coursePrice - discount) - this.totalPaid;
      }
    });

    await this.delay(200);

    this.subscriptionsStatistics = null;
    this.userService.getSubscriptionsStatistics(this.currentSub.reference).subscribe(w => {
      if (w != null) {
        this.subscriptionsStatistics = w;
        this.subscriptionsStatisticsExams = this.subscriptionsStatistics.filter(x => x.type == 'EXAM');
        this.subscriptionsStatisticsLibraries = this.subscriptionsStatistics.filter(x => x.type == 'LIBRARY_FOLDER');
        this.subscriptionsStatisticsVideos = this.subscriptionsStatistics.filter(x => x.type == 'VIDEO_FOLDER');

        this.statistics();
      }
    });

    if (this.currentSub.course != null && this.currentSub.course.id != -1) {
      this.changeCourse(false);
    } else {
      this.currentSub.course = new BasicDto(-1, '', '', null);
    }

    if (this.currentSub.courseType != null && this.currentSub.courseType.id != -1) {
      this.changeCourseType(false);
    } else {
      this.currentSub.courseType = new BasicDto(-1, '', '', null);
    }

    if (this.currentSub.modality != null && this.currentSub.modality.id != -1) {
      this.changeModality(false);
    } else {
      this.currentSub.modality = new BasicDto(-1, '', '', null);
    }

    if (this.currentSub.site != null && this.currentSub.site.id != -1) {
      this.changeSite(false);
    } else {
      this.currentSub.site = new BasicDto(-1, '', '', null);
    }

    if (this.currentSub.turn != null && this.currentSub.turn.id != -1) {
      this.changeTurn();
    } else {
      this.currentSub.turn = new BasicDto(-1, '', '', null);
    }

  }

  selectAllbs(bool){
    this.dssd.bsName = bool;
    this.dssd.bsSurname = bool;
    this.dssd.bsAddress = bool;
    this.dssd.bsProvince = bool;
    this.dssd.bsEmail = bool;
    this.dssd.bsTown = bool;
    this.dssd.bsMobile = bool;
    this.dssd.bsCP = bool;
    this.dssd.bsNIF = bool;
    this.dssd.bsCountry = bool;
    this.dssd.bsUniversity = bool;
    this.dssd.bsHospital = bool;
  }

  selectAllbc(bool){
    this.dssd.bcCourseprice = bool;
    this.dssd.bcEnrollment = bool;
    this.dssd.bcMonthly = bool;
    this.dssd.bcNumofmonths = bool;
    this.dssd.bcCoupons = bool;
  }

  selectAllbb(bool){
    this.dssd.bbReference = bool;
    this.dssd.bbEnabled = bool;
    this.dssd.bbTestsubscription = bool;
    this.dssd.bbDisabledreason = bool;
    this.dssd.bbWorkgroup = bool;
    this.dssd.bbTutor = bool;
    this.dssd.bbCourse = bool;
    this.dssd.bbCoursetype = bool;
    this.dssd.bbModality = bool;
    this.dssd.bbSite = bool;
    this.dssd.bbTurn = bool;
    this.dssd.bbStartdate = bool;
    this.dssd.bbEnddate = bool;
    this.dssd.bbComments = bool;
    this.dssd.bbScholarship = bool;
  }

  selectAllbp(bool){
    this.dssd.bpPaid = bool;
    this.dssd.bpRestore = bool;
    this.dssd.bpStartdate = bool;
    this.dssd.bpEnddate = bool;
    this.dssd.bpDonedate = bool;
    this.dssd.bpRestoredate = bool;
    this.dssd.bpTypeofpayment = bool;
    this.dssd.bpFormatofpayment = bool;
    this.dssd.bpPrice = bool;
    this.dssd.bpCurrency = bool;
  }


  setMoment(txt) {
    return moment(txt);
  }

  getCalendar(subReference) {
    this.calendarService.getCalendar(subReference).subscribe((x: CalendarDto[]) => {
      if (x != null) {

        if (this.shared.CURRENT_LANGUAGE == 'ES') {
          this.language = "es_ES"
        } else if (this.shared.CURRENT_LANGUAGE == 'US') {
          this.language = "en_US"
        }

        var events = [];
        x.forEach(obj => {
          events.push({
            title: obj.title,
            startTime: new Date(moment(obj.startDate).tz(this.shared.CURRENT_CORP.timezone).toDate()),
            endTime: new Date(moment(obj.endDate).tz(this.shared.CURRENT_CORP.timezone).toDate()),
            allDay: false,
            myEvent: obj
          });
        });
        this.eventSource = events;
      }
    });
  }

  async downloadContent(){
    this.isDownloading = true;
    let student = false;
    let subscription = false;
    let subscriptionPayment = false;
    let payment = false;
    let headers = [];
    let data = [];
    let waitingForData = true;

    if(this.dssd.bsName){headers.push('Name');student = true;}
    if(this.dssd.bsSurname){headers.push('Surname');student = true;}
    if(this.dssd.bsAddress){headers.push('Address');student = true;}
    if(this.dssd.bsProvince){headers.push('Province');student = true;}
    if(this.dssd.bsEmail){headers.push('Email');student = true;}
    if(this.dssd.bsTown){headers.push('Town');student = true;}
    if(this.dssd.bsMobile){headers.push('Mobile');student = true;}
    if(this.dssd.bsCP){headers.push('CP');student = true;}
    if(this.dssd.bsNIF){headers.push('NIF');student = true;}
    if(this.dssd.bsCountry){headers.push('Country');student = true;}
    if(this.dssd.bsUniversity){headers.push('University');student = true;}
    if(this.dssd.bsHospital){headers.push('Hospital');student = true;}

    if(this.dssd.bbReference){headers.push('Reference');subscription = true;}
    if(this.dssd.bbEnabled){headers.push('Enabled');subscription = true;}
    if(this.dssd.bbTestsubscription){headers.push('Test subscription');subscription = true;}
    if(this.dssd.bbDisabledreason){headers.push('Disabled reason');subscription = true;}
    if(this.dssd.bbWorkgroup){headers.push('Workgroup');subscription = true;}
    if(this.dssd.bbTutor){headers.push('Tutor');subscription = true;}
    if(this.dssd.bbScholarship){headers.push('Scholarship');subscription = true;}
    if(this.dssd.bbCourse){headers.push('Course');subscription = true;}
    if(this.dssd.bbCoursetype){headers.push('Course type');subscription = true;}
    if(this.dssd.bbModality){headers.push('Modality');subscription = true;}
    if(this.dssd.bbSite){headers.push('Site');subscription = true;}
    if(this.dssd.bbTurn){headers.push('Turn');subscription = true;}
    if(this.dssd.bbStartdate){headers.push('Start date');subscription = true;}
    if(this.dssd.bbEnddate){headers.push('End date');subscription = true;}
    if(this.dssd.bbComments){headers.push('Comments');subscription = true;}

    if(this.dssd.bcCourseprice){headers.push('Course price');subscriptionPayment = true;}
    if(this.dssd.bcEnrollment){headers.push('Enrollment');subscriptionPayment = true;}
    if(this.dssd.bcMonthly){headers.push('Monthly');subscriptionPayment = true;}
    if(this.dssd.bcNumofmonths){headers.push('Num of months');subscriptionPayment = true;}
    if(this.dssd.bcCoupons){headers.push('Coupons');subscriptionPayment = true;}

    if(this.dssd.bpPaid){headers.push('Paid');payment = true;}
    if(this.dssd.bpRestore){headers.push('Restore');payment = true;}
    if(this.dssd.bpStartdate){headers.push('Start Date');payment = true;}
    if(this.dssd.bpEnddate){headers.push('End Date');payment = true;}
    if(this.dssd.bpDonedate){headers.push('Done date');payment = true;}
    if(this.dssd.bpRestoredate){headers.push('Restore date');payment = true;}
    if(this.dssd.bpTypeofpayment){headers.push('Type of payment');payment = true;}
    if(this.dssd.bpFormatofpayment){headers.push('Format of payment');payment = true;}
    if(this.dssd.bpPrice){headers.push('Price');payment = true;}
    if(this.dssd.bpCurrency){headers.push('Currency');payment = true;}

    if(student || subscription || subscriptionPayment || payment) {
      this.students.forEach((s, index)=>{
        let data_student = [];
        if(this.dssd.bsName){data_student.push(this.checkNull(s.name))}
        if(this.dssd.bsSurname){data_student.push(this.checkNull(s.surname))}
        if(this.dssd.bsAddress){data_student.push(this.checkNull(s.address))}
        if(this.dssd.bsProvince){data_student.push(this.checkNull(s.province))}
        if(this.dssd.bsEmail){data_student.push(this.checkNull(s.email))}
        if(this.dssd.bsTown){data_student.push(this.checkNull(s.town))}
        if(this.dssd.bsMobile){data_student.push(this.checkNull(s.mobile))}
        if(this.dssd.bsCP){data_student.push(this.checkNull(s.cp))}
        if(this.dssd.bsNIF){data_student.push(this.checkNull(s.nif))}
        if(this.dssd.bsCountry){
          if(s.country != null){
            let t1 = this.countries.find(x=>x.id == s.country);
            if(t1 != null) {
              data_student.push(t1.name);
            }else{
              data_student.push("");
            }
          }else{
            data_student.push("");
          }
        }
        if(this.dssd.bsUniversity){
          if(s.universityId != null){
            data_student.push(this.commonUserData.universities.find(x=>x.id == s.universityId).name);
          }else{
            data_student.push("");
          }
        }
        if(this.dssd.bsHospital){
          if(s.hospitalId != null){
            data_student.push(this.commonUserData.hospitals.find(x=>x.id == s.hospitalId).name);
          }else{
            data_student.push("");
          }
        }

        if(subscription || subscriptionPayment || payment) {
          this.userService.getSubscriptions(s.reference).subscribe(y => {
            if (y != null) {
              y.forEach(b => {
                console.log(s.name+'---'+this.applySubscriptionFilter(b));
                if(this.applySubscriptionFilter(b)) {
                  let data_subscription = [];
                  if (this.dssd.bbReference) {
                    data_subscription.push(this.checkNull(b.reference))
                  }
                  if (this.dssd.bbEnabled) {
                    data_subscription.push(this.checkNull(b.enabled))
                  }
                  if (this.dssd.bbTestsubscription) {
                    data_subscription.push(this.checkNull(b.testSubscription))
                  }
                  if (this.dssd.bbDisabledreason) {
                    data_subscription.push(this.checkNull(b.disabledReason))
                  }
                  if (this.dssd.bbWorkgroup) {
                    data_subscription.push(this.checkNull(b.workgroup))
                  }
                  if (this.dssd.bbTutor) {
                    data_subscription.push(this.checkNull(b.tutor).map(c=> this.commonUserData.tutor.find(x=>x.id == c).name).join(', '));
                  }
                  if (this.dssd.bbScholarship) {
                    data_subscription.push(this.checkNull(b.scholarship).map(c=> this.commonUserData.scholarship.find(x=>x.id == c).title).join(', '));
                  }
                  if (this.dssd.bbCourse) {
                    if (b.course != null) {
                      data_subscription.push(this.checkNull(b.course.name))
                    } else {
                      data_subscription.push(this.checkNull(b.course))
                    }
                  }
                  if (this.dssd.bbCoursetype) {
                    if (b.courseType != null) {
                      data_subscription.push(this.checkNull(b.courseType.name))
                    } else {
                      data_subscription.push(this.checkNull(b.courseType))
                    }
                  }
                  if (this.dssd.bbModality) {
                    if (b.modality != null) {
                      data_subscription.push(this.checkNull(b.modality.name))
                    } else {
                      data_subscription.push(this.checkNull(b.modality))
                    }
                  }
                  if (this.dssd.bbSite) {
                    if (b.modality != null) {
                      data_subscription.push(this.checkNull(b.site.name))
                    } else {
                      data_subscription.push(this.checkNull(b.site))
                    }
                  }
                  if (this.dssd.bbTurn) {
                    if (b.modality != null) {
                      data_subscription.push(this.checkNull(b.turn.name))
                    } else {
                      data_subscription.push(this.checkNull(b.turn))
                    }
                  }
                  if (this.dssd.bbStartdate) {
                    data_subscription.push(this.formatDate(b.startDate))
                  }
                  if (this.dssd.bbEnddate) {
                    data_subscription.push(this.formatDate(b.endDate))
                  }
                  if (this.dssd.bbComments) {
                    data_subscription.push(b.comments)
                  }

                  if (subscriptionPayment || payment) {
                    this.userService.getSubscriptionsPayments(b.reference).subscribe(w => {
                      if (w != null) {
                        if (this.applySubscriptionPFilter(w)) {
                          let data_subscriptionP = [];
                          if (this.dssd.bcCourseprice) {
                            data_subscriptionP.push(this.checkNull(w.coursePrice))
                          }
                          if (this.dssd.bcEnrollment) {
                            data_subscriptionP.push(this.checkNull(w.enrollment))
                          }
                          if (this.dssd.bcMonthly) {
                            data_subscriptionP.push(this.checkNull(w.monthly))
                          }
                          if (this.dssd.bcNumofmonths) {
                            data_subscriptionP.push(this.checkNull(w.numOfMonths))
                          }
                          if (this.dssd.bcCoupons) {
                            data_subscriptionP.push(this.checkNull(w.discountCoupon))
                          }

                          if (payment && w.payments.length>0) {
                            w.payments.forEach(p => {
                              if (this.applyPaymentFilter(p)) {
                                let data_payment = [];
                                if (this.dssd.bpPaid) {
                                  data_payment.push(this.checkNull(p.done))
                                }
                                if (this.dssd.bpRestore) {
                                  data_payment.push(this.checkNull(p.restore))
                                }
                                if (this.dssd.bpStartdate) {
                                  data_payment.push(this.formatDate(p.startDate))
                                }
                                if (this.dssd.bpEnddate) {
                                  data_payment.push(this.formatDate(p.endDate))
                                }
                                if (this.dssd.bpDonedate) {
                                  data_payment.push(this.formatDate(p.doneDate))
                                }
                                if (this.dssd.bpRestoredate) {
                                  data_payment.push(this.formatDate(p.restoDate))
                                }
                                if (this.dssd.bpTypeofpayment) {
                                  data_payment.push(this.checkNull(p.typeOfPayment))
                                }
                                if (this.dssd.bpFormatofpayment) {
                                  data_payment.push(this.checkNull(p.formatOfPayment))
                                }
                                if (this.dssd.bpPrice) {
                                  data_payment.push(this.checkNull(p.price))
                                }
                                if (this.dssd.bpCurrency) {
                                  data_payment.push(this.checkNull(p.currency))
                                }

                                const d: { [index: number]: string } = {};
                                data_student.concat(data_subscription).concat(data_subscriptionP).concat(data_payment).forEach((value, index) => {
                                  d[index] = value;
                                });
                                data.push(d);
                                if((index+1) == this.students.length) {
                                  waitingForData = false;
                                }
                              }
                            });
                          } else {
                            const d: { [index: number]: string } = {};
                            data_student.concat(data_subscription).concat(data_subscriptionP).forEach((value, index) => {
                              d[index] = value;
                            });
                            data.push(d);
                            if((index+1) == this.students.length) {
                              waitingForData = false;
                            }
                          }
                        }
                      }
                    });
                  } else {
                    const d: { [index: number]: string } = {};
                    data_student.concat(data_subscription).forEach((value, index) => {
                      d[index] = value;
                    });
                    data.push(d);
                    if((index+1) == this.students.length) {
                      waitingForData = false;
                    }
                  }
                }
              });
            }
          });
        }else{
          const d : { [index: number]: string } = {};
            data_student.forEach((value, index) => {
              d[index] = value;
            });
          data.push(d);
          if((index+1) == this.students.length) {
            waitingForData = false;
          }
        }
      });
    }

    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      headers: headers
    };

    let count = 0;
    while(waitingForData && count<120){
      await this.delay(500);
      count++;
    }
    await this.delay(1000);

    localStorage.setItem('DOWNLOAD_USER_SETTINGS', JSON.stringify(this.dssd));
    new Angular5Csv(data, "ExportUsers_"+moment().format('yyyyMMDDHHmmss'), options);
    this.isDownloading = false;
  }

  checkNull(data){
    if(data==null || data==undefined) {
      return "";
    }else{
      return data;
    }
  }

  formatDate(data){
    if(data==null) {
      return "";
    }else{
      return moment.tz(data, this.shared.SERVER_TIMEZONE).tz(this.shared.CURRENT_CORP.timezone).format('DD/MM/yyyy HH:mm:ss');
    }
  }

  applySubscriptionFilter(s: SubscriptionEntity){
    if(this.searchSubscription.reference != null){
      if(s.reference.includes(this.searchSubscription.reference)){
        return false;
      }
    }

    if(this.searchSubscription.enabled != null){
      if(this.searchSubscription.enabled != s.enabled){
        return false;
      }
    }

    if(this.searchSubscription.testSubscription != null){
      if(this.searchSubscription.testSubscription != s.testSubscription){
        return false;
      }
    }

    if(this.searchSubscription.disabledReason != null){
      if(this.searchSubscription.disabledReason.includes(s.disabledReason)){
        return false;
      }
    }

    if(this.searchSubscription.workgroup != null){
      if(!s.workgroup.every(element => this.searchSubscription.workgroup.includes(element))){
        return false;
      }
    }

    if(this.searchSubscription.tutor != null){
      if(!s.tutor.every(element => this.searchSubscription.tutor.includes(element))){
        return false;
      }
    }

    if(this.searchSubscription.course != null && s.course != null){
      if(!this.searchSubscription.course.includes(s.course.id) && !this.searchSubscription.courseSearchType){
        return false;
      }else if(this.searchSubscription.course.includes(s.course.id) && this.searchSubscription.courseSearchType){
        return false;
      }
    }

    if(this.searchSubscription.courseType != null && s.courseType != null){
      if(!this.searchSubscription.courseType.includes(s.courseType.id) && !this.searchSubscription.courseTypeSearchType){
        return false;
      }else if(this.searchSubscription.courseType.includes(s.courseType.id) && this.searchSubscription.courseTypeSearchType){
        return false;
      }
    }

    if(this.searchSubscription.modality != null && s.modality != null){
      if(!this.searchSubscription.modality.includes(s.modality.id) && !this.searchSubscription.modalitySearchType){
        return false;
      }else if(this.searchSubscription.modality.includes(s.modality.id) && this.searchSubscription.modalitySearchType){
        return false;
      }
    }

    if(this.searchSubscription.site != null && s.site != null){
      if(!this.searchSubscription.site.includes(s.site.id) && !this.searchSubscription.siteSearchType){
        return false;
      }else if(this.searchSubscription.site.includes(s.site.id) && this.searchSubscription.siteSearchType){
        return false;
      }
    }

    if(this.searchSubscription.turn != null && s.turn != null){
      if(!this.searchSubscription.turn.includes(s.turn.id) && !this.searchSubscription.turnSearchType){
        return false;
      }else if(this.searchSubscription.turn.includes(s.turn.id) && this.searchSubscription.turnSearchType){
        return false;
      }
    }

    if(this.searchSubscription.startDateF != null || this.searchSubscription.startDateT != null){
      if(this.searchSubscription.startDateF != null && this.searchSubscription.startDateT != null){
        if(s.startDate < this.searchSubscription.startDateF || s.startDate > this.searchSubscription.startDateT){
          return false;
        }
      }else if(this.searchSubscription.startDateF != null){
        if(s.startDate < this.searchSubscription.startDateF){
          return false;
        }
      }else if(this.searchSubscription.startDateT != null){
        if(s.startDate > this.searchSubscription.startDateT){
          return false;
        }
      }
    }

    if(this.searchSubscription.endDateF != null || this.searchSubscription.endDateT != null){
      if(this.searchSubscription.endDateF != null && this.searchSubscription.endDateT != null){
        if(s.endDate < this.searchSubscription.endDateF || s.startDate > this.searchSubscription.endDateT){
          return false;
        }
      }else if(this.searchSubscription.endDateF != null){
        if(s.endDate < this.searchSubscription.endDateT){
          return false;
        }
      }else if(this.searchSubscription.startDateT != null){
        if(s.endDate > this.searchSubscription.endDateT){
          return false;
        }
      }
    }

    if(this.searchSubscription.comments != null){
      if(s.comments.includes(this.searchSubscription.comments)){
        return false;
      }
    }

    return true;
  }

  applySubscriptionPFilter(s: SubscriptionPaymentsEntity){
    if(this.searchSubscriptionPayments.coursePrice != null){
      if(this.searchSubscriptionPayments.coursePrice != s.coursePrice){
        return false;
      }
    }

    if(this.searchSubscriptionPayments.enrollment != null){
      if(this.searchSubscriptionPayments.enrollment != s.enrollment){
        return false;
      }
    }

    if(this.searchSubscriptionPayments.monthly != null){
      if(this.searchSubscriptionPayments.monthly != s.monthly){
        return false;
      }
    }

    if(this.searchSubscriptionPayments.numOfMonths != null){
      if(this.searchSubscriptionPayments.numOfMonths != s.numOfMonths){
        return false;
      }
    }

    if(this.searchSubscriptionPayments.discountCoupon != null){
      if(this.searchSubscriptionPayments.discountCoupon != s.discountCoupon){
        return false;
      }
    }

    return true;
  }

  applyPaymentFilter(p: Payment){
    if(this.searchPayments.done != null){
      if(this.searchPayments.done != p.done){
        return false;
      }
    }
    if(this.searchPayments.restore != null){
      if(this.searchPayments.restore != p.restore){
        return false;
      }
    }
    if(this.searchPayments.startDateF != null || this.searchPayments.startDateT != null){
      if(this.searchPayments.startDateF != null && this.searchPayments.startDateT != null){
        if(p.startDate < this.searchPayments.startDateF || p.startDate > this.searchPayments.startDateT){
          return false;
        }
      }else if(this.searchPayments.startDateF != null){
        if(p.startDate < this.searchPayments.startDateF){
          return false;
        }
      }else if(this.searchPayments.startDateT != null){
        if(p.startDate > this.searchPayments.startDateT){
          return false;
        }
      }
    }

    if(this.searchPayments.endDateF != null || this.searchPayments.endDateT != null){
      if(this.searchPayments.endDateF != null && this.searchPayments.endDateT != null){
        if(p.endDate < this.searchPayments.endDateF || p.endDate > this.searchPayments.endDateT){
          return false;
        }
      }else if(this.searchPayments.endDateF != null){
        if(p.endDate < this.searchPayments.endDateF){
          return false;
        }
      }else if(this.searchPayments.endDateT != null){
        if(p.endDate > this.searchPayments.endDateT){
          return false;
        }
      }
    }

    if(this.searchPayments.doneDateF != null || this.searchPayments.doneDateT != null){
      if(this.searchPayments.doneDateF != null && this.searchPayments.doneDateT != null){
        if(p.doneDate < this.searchPayments.doneDateF || p.doneDate > this.searchPayments.doneDateT){
          return false;
        }
      }else if(this.searchPayments.doneDateF != null){
        if(p.doneDate < this.searchPayments.doneDateF){
          return false;
        }
      }else if(this.searchPayments.doneDateT != null){
        if(p.doneDate > this.searchPayments.doneDateT){
          return false;
        }
      }
    }


    if(this.searchPayments.restoreDateF != null || this.searchPayments.restoreDateT != null){
      if(this.searchPayments.restoreDateF != null && this.searchPayments.restoreDateT != null){
        if(p.restoDate < this.searchPayments.restoreDateF || p.restoDate > this.searchPayments.restoreDateT){
          return false;
        }
      }else if(this.searchPayments.restoreDateF != null){
        if(p.restoDate < this.searchPayments.restoreDateF){
          return false;
        }
      }else if(this.searchPayments.restoreDateT != null){
        if(p.restoDate > this.searchPayments.restoreDateT){
          return false;
        }
      }
    }

    if(this.searchPayments.typeOfPayment != null){
      console.log('----------------------------------------------------');
      console.log(this.typeOfPayments);
      console.log(p.typeOfPayment);
      console.log('----------------------------------------------------');
      let t2 = this.typeOfPayments.find(z=> z.name == p.typeOfPayment);
      if(t2 != null) {
        if (this.searchPayments.typeOfPayment.includes(t2.id)){
          return false;
        }
      }
    }

    if(this.searchPayments.formatOfPayment != null){
      if(this.searchPayments.formatOfPayment.includes(this.formatOfPayments.find(z=> z.name == p.formatOfPayment).id)){
        return false;
      }
    }

    if(this.searchPayments.priceF != null || this.searchPayments.priceT != null){
      if(this.searchPayments.priceF != null && this.searchPayments.priceT != null){
        if(p.price < this.searchPayments.priceF || p.price > this.searchPayments.priceT){
          return false;
        }
      }else if(this.searchPayments.priceF != null){
        if(p.price < this.searchPayments.priceF){
          return false;
        }
      }else if(this.searchPayments.priceT != null){
        if(p.price > this.searchPayments.priceT){
          return false;
        }
      }
    }

    if(this.searchPayments.currency != null){
      if(!this.searchPayments.currency.includes(p.currency)){
        return false;
      }
    }

    return true;
  }

  eventSelected(reference, type) {
    if (type == 1) {
      this.shared.goTo(['/', 'tabs', 'video', 'viewer', reference]);
    } else if (type == 2) {
      this.shared.goTo(['/', 'tabs', 'library', 'viewer', reference]);
    } else if (type == 3) {
      this.shared.goTo(['/', 'tabs', 'exams', 'desk', reference]);
    }
  }

  isToday: boolean;
  calendar = {
    mode: 'month' as CalendarMode,
    locale: this.language,
    startingDayWeek: 1,
    step: 30 as Step,
    currentDate: new Date()
  };

  onViewTitleChanged(title) {
    this.viewTitle = title;
  }

  changeMode(mode) {
    this.calendar.mode = mode;
  }

  today() {
    this.calendar.currentDate = new Date();
    this.myCalendar.locale = 'es_ES';
    this.calendar.locale = 'es_ES';
    this.language = 'es_ES'
    this.myCalendar.loadEvents();
  }

  onTimeSelected(ev) {
    console.log('Selected time: ' + ev.selectedTime + ', hasEvents: ' +
      (ev.events !== undefined && ev.events.length !== 0) + ', disabled: ' + ev.disabled);
  }

  onCurrentDateChanged(event: Date) {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    event.setHours(0, 0, 0, 0);
    this.isToday = today.getTime() === event.getTime();
  }

  onRangeChanged(ev) {
    console.log('range changed: startTime: ' + ev.startTime + ', endTime: ' + ev.endTime);
  }

  openSearchUser() {
    this.rightDiv = 0;
  }


  markDisabled = (date: Date) => {
    var current = new Date();
    current.setHours(0, 0, 0);
    return date < current;
  };

  async changeCourse(bool) {
    while(this.basicData == null || this.coupons == null){
      await this.delay(200);
    }

    this.courseIndex = this.basicData.findIndex(x => x.id == this.currentSub.course.id);
    this.currentSub.course = this.basicData.find(x => x.id == this.currentSub.course.id);

    this.userService.getStaticCalendars(this.currentSub.course.id).subscribe(z => {
      if (z != null) {
        this.staticCalendars = z;
      }
    });

    if (this.currentSub.course != null && this.currentSub.permissionRoleId == null) {
      this.currentSub.permissionRoleId = this.commonUserData.course.find(x => x.id == this.currentSub.course.id).defaultRole;
    }

    if (this.currentSub.course != null) {
      this.coupons = this.coupons.filter(x => x.courseId == this.currentSub.course.id || x.courseId == null);
    }

    if (bool) {
      this.courseTypeIndex = null;
      this.modalityIndex = null;
      this.siteIndex = null;
      this.currentSub.courseType = new BasicDto(-1, '', '', null);
      this.currentSub.modality = new BasicDto(-1, '', '', null);
      this.currentSub.site = new BasicDto(-1, '', '', null);
      this.currentSub.turn = new BasicDto(-1, '', '', null);
    }
  }

  async changeCourseType(bool) {
    while(this.basicData == null){
      await this.delay(200);
    }

    this.courseTypeIndex = this.basicData[this.courseIndex].list.findIndex(x => x.id == this.currentSub.courseType.id);
    this.currentSub.courseType = this.basicData[this.courseIndex].list.find(x => x.id == this.currentSub.courseType.id);

    if (this.currentSub.courseType != null && this.currentSub.permissionRoleId == null) {
      this.currentSub.permissionRoleId = this.commonUserData.courseType.find(x => x.id == this.currentSub.courseType.id).defaultRole;
    }

    if (bool) {
      this.modalityIndex = null;
      this.siteIndex = null;
      this.currentSub.modality = new BasicDto(-1, '', '', null);
      this.currentSub.site = new BasicDto(-1, '', '', null);
      this.currentSub.turn = new BasicDto(-1, '', '', null);
    }
  }

  async changeModality(bool) {
    while(this.basicData == null){
      await this.delay(200);
    }

    this.modalityIndex = this.basicData[this.courseIndex].list[this.courseTypeIndex].list.findIndex(x => x.id == this.currentSub.modality.id);
    this.currentSub.modality = this.basicData[this.courseIndex].list[this.courseTypeIndex].list.find(x => x.id == this.currentSub.modality.id);

    if (this.currentSub.modality != null && this.currentSub.permissionRoleId == null) {
      this.currentSub.permissionRoleId = this.commonUserData.modality.find(x => x.id == this.currentSub.modality.id).defaultRole;
    }

    if (bool) {
      this.siteIndex = null;
      this.currentSub.site = new BasicDto(-1, '', '', null);
      this.currentSub.turn = new BasicDto(-1, '', '', null);
    }
  }

  async changeSite(bool) {
    while(this.basicData == null){
      await this.delay(200);
    }
    this.siteIndex = this.basicData[this.courseIndex].list[this.courseTypeIndex].list[this.modalityIndex].list.findIndex(x => x.id == this.currentSub.site.id);
    this.currentSub.site = this.basicData[this.courseIndex].list[this.courseTypeIndex].list[this.modalityIndex].list.find(x => x.id == this.currentSub.site.id);

    if (this.currentSub.site != null && this.currentSub.permissionRoleId == null) {
      this.currentSub.permissionRoleId = this.commonUserData.site.find(x => x.id == this.currentSub.site.id).defaultRole;
    }

    if (bool) {
      this.currentSub.turn = new BasicDto(-1, '', '', null);
    }
  }

  async changeTurn() {
    while(this.basicData == null){
      await this.delay(200);
    }

    this.currentSub.turn = this.basicData[this.courseIndex].list[this.courseTypeIndex].list[this.modalityIndex].list[this.siteIndex].list.find(x => x.id == this.currentSub.turn.id);

    if (this.currentSub.turn != null && this.currentSub.permissionRoleId == null) {
      this.currentSub.permissionRoleId = this.commonUserData.turn.find(x => x.id == this.currentSub.turn.id).defaultRole;
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}

