import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {CourseEntity} from "../model/CourseEntity";
import {CourseTypeEntity} from "../model/CourseTypeEntity";
import {ModalityEntity} from "../model/ModalityEntity";
import {TurnEntity} from "../model/TurnEntity";
import {SiteEntity} from "../model/SiteEntity";
import {WorkgroupEntity} from "../model/WorkgroupEntity";
import {TopicEntity} from "../model/TopicEntity";
import {SubjectEntity} from "../model/SubjectEntity";
import {ModuleEntity} from "../model/ModuleEntity";
import {ModuleFolderEntity} from "../model/ModuleFolderEntity";
import {ModuleItemEntity} from "../model/ModuleItemEntity";
import {PackageItemEntity} from "../model/CalendarPackageItem";


@Injectable()
export class ModuleService extends ParentService{

  public getModules(corpId) {
    return this.http.get<ModuleEntity[]>(this.shared.MAGNAMENT_URL+'/getModules/'+corpId, this.shared.headers());
  }

  public saveModule(module) {
    return this.http.post<ModuleEntity>(this.shared.MAGNAMENT_URL+'/saveModule', module);
  }


  public getModuleFolders(moduleId) {
    return this.http.get<ModuleFolderEntity[]>(this.shared.MAGNAMENT_URL+'/getModuleFolders/'+moduleId, this.shared.headers());
  }

  public saveModuleFolder(moduleFolder) {
    return this.http.post<ModuleFolderEntity>(this.shared.MAGNAMENT_URL+'/saveModuleFolder', moduleFolder);
  }

  public deleteModuleFolder(id) {
    return this.http.get(this.shared.MAGNAMENT_URL+'/deleteModuleFolder/'+id);
  }

  public saveModuleFolders(folders) {
    return this.http.post<ModuleFolderEntity[]>(this.shared.MAGNAMENT_URL+'/saveModuleFolders', folders);
  }


  public getModuleItems(moduleFolderId) {
    return this.http.get<ModuleItemEntity[]>(this.shared.MAGNAMENT_URL+'/getModuleItems/'+moduleFolderId, this.shared.headers());
  }

  public saveModuleItem(moduleItem) {
    return this.http.post<ModuleItemEntity>(this.shared.MAGNAMENT_URL+'/saveModuleItem', moduleItem);
  }

  public deleteModuleItem(id) {
    return this.http.get(this.shared.MAGNAMENT_URL+'/deleteModuleItem/'+id);
  }

  public saveModuleItems(items) {
    return this.http.post<ModuleItemEntity[]>(this.shared.MAGNAMENT_URL+'/saveModuleItems', items);
  }

}
